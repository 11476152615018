jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-back",
			"position-top",
		],
		navbar: {
			add: true,
			title: ""
		},
		slidingSubmenus: true,
		screenReader: {
			aria: true,
			text: true,
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}

		calculateMobileMenuMargin();
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link-2").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-item-li").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-item-li").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* webflow */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"login-form","name":"Login Form","value":{"style":{},"triggers":[{"type":"click","selector":".login-form-wrapper","preserve3d":true,"stepsA":[{"opacity":1,"transition":"transform 450ms ease 0, opacity 450ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0,"transition":"opacity 450ms ease 0, transform 450ms ease 0","x":"0px","y":"-105px","z":"0px"}]}]}},
		{"slug":"expand-search-bar","name":"Expand Search Bar","value":{"style":{},"triggers":[]}},
		{"slug":"search","name":"Search","value":{"style":{},"triggers":[{"type":"click","selector":".search","stepsA":[{"opacity":1,"width":"200px","transition":"width 450ms ease 0, opacity 200 ease 0"}],"stepsB":[{"width":"38px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"search-2","name":"Search 2","value":{"style":{},"triggers":[{"type":"click","selector":".mobile-search","stepsA":[{"opacity":1,"width":"200px","transition":"width 450ms ease 0, opacity 200 ease 0"}],"stepsB":[{"width":"30px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"arrow","name":"arrow","value":{"style":{},"triggers":[{"type":"hover","selector":".grey-arrow","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"news-see-all","name":"news see all","value":{"style":{},"triggers":[{"type":"hover","selector":".news-arrow","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"testimonial-see-all","name":"testimonial see all","value":{"style":{},"triggers":[{"type":"hover","selector":".testimonial-arrow","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"slider-arrow","name":"Slider Arrow","value":{"style":{},"triggers":[{"type":"hover","selector":".grey-arrow","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"arrow-2","name":"arrow 2","value":{"style":{},"triggers":[{"type":"hover","selector":".grey-arrow-2","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"arrow-3","name":"arrow 3","value":{"style":{},"triggers":[{"type":"hover","selector":".grey-arrow-3","stepsA":[{"width":"35px","transition":"width 450ms ease 0"}],"stepsB":[{"width":"0px","transition":"width 450ms ease 0"}]}]}},
		{"slug":"dropdown-hover-button","name":"Dropdown Hover - Button","value":{"style":{},"triggers":[]}},
		{"slug":"dropdown-hover-for-list","name":"Dropdown Hover - For List","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"display":"block"}],"stepsB":[{"wait":250,"display":"none"}]}]}}
	]);
	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions 2.0: Init
	 */
	Webflow.require('ix2').init(
	{"events":{},"actionLists":{},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}
	);
	/* eslint-enable */

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	/* Login Form */
	$(".login-button-toggle").click(function() {
		if ( $('.login-div-2').is(':visible') ) {
			$('.login-div-2').slideUp();
		}
		else {
			$('.login-div-2').slideDown();
		}
	});

	$(".login-homepage-button-toggle").click(function() {
		if ($(".account-login-wrapper").is(":visible")) {
			$(".account-login-wrapper").slideUp();
		}
		else {
			$(".account-login-wrapper").slideDown();
		}
	});


	$(window).on('load resize', function() {
		calculateMobileMenuMargin();
	});

	function calculateMobileMenuMargin() {
		$(".mobile-navigation-menu").css("margin-top", $("#header").height());
	}
});

function addStickyHeader() {
	if ( ($(window).scrollTop() > 1) ) {
		$('#header').addClass('sticky');
	}
	else {
		$('#header').removeClass('sticky');
	}
}

function cloneAttributes(element, sourceNode) {
	let attr;
	let attributes = Array.prototype.slice.call(sourceNode.attributes);
	while ((attr = attributes.pop())) {
		element.setAttribute(attr.nodeName, attr.nodeValue);
	}
}

const updateCalculator = () => {
	// Check if calculator is loaded
	if ( document.getElementById("tvcDetailTable1") === null ) {
		return;
	};

	// finds the two elements that need to be updated
	for (let i = 0; i < 8; i++) {
		let fieldSetToChange = document.getElementById("tvcDetailTable1").children[0].children[0].children[0].children[i];
		if (fieldSetToChange && fieldSetToChange.tagName == "FIELDSET") {
			let tableToChangeIDPostFix = (i / 2) + 1;
			let tableToChange = document.getElementById("tvcSubDetailTable" + tableToChangeIDPostFix);
			//adds role = presentation
			tableToChange.setAttribute("role", "presentation");

			childTHs = tableToChange.querySelectorAll("th");
			for (var j = 0; j < childTHs.length; j++) {
				item = childTHs[j];
				let innerHTML = item.innerHTML;
				let newTD = document.createElement("td");
				cloneAttributes(newTD, item);
				newTD.innerHTML = innerHTML;
				item.parentNode.replaceChild(newTD, item);
			}

			//changes fieldset to div
			let innerHTML = fieldSetToChange.innerHTML;
			let newDiv = document.createElement("div");
			newDiv.innerHTML = innerHTML;
			fieldSetToChange.parentNode.replaceChild(newDiv, fieldSetToChange);
			// ends the interval
			clearInterval(updateCalculatorInterval);
		}
	}
};
//starts the interval
const updateCalculatorInterval = setInterval(updateCalculator, 500);
